.wrapper {
  font-family: "Open Sans", sans-serif;
  display: flex;
  flex-direction: column;
  background-image: url("images/image_backgroundDesktop.jpg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  height: 100vh;
  width: 100vw;
  justify-content: center;
  align-items: center;
  user-drag: none;
  -webkit-user-drag: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;

  .rootWheel {
    position: relative;
  }

  .spin-button {
    position: absolute;
    outline: none;
    border: none;
    cursor: pointer;
    background: transparent;

    &:disabled {
      cursor: initial;
      filter: contrast(0.8);
    }
  }

  .wheel {
    width: calc(40vh + 20px);
    z-index: 100;
  }

  .absolute {
    position: absolute;
    bottom: 0;
  }

  .button {
    text-decoration: none;
    outline: none;
    border: none;
    z-index: 100;
    color: #000000;
    background: #f1cf00;
    align-self: center;
    font-weight: bold;
    font-size: 16px;
    border-radius: 38px;
    max-width: 500px;
    width: 90%;
    margin-top: 8vh;
    padding: 20px 56px;
    transition: 0.5s ease all;
    cursor: pointer;

    @media screen and (max-width: 800px) {
      margin-top: 1vh;
    }

    &:hover {
      transform: scale(1.05);
    }

    &:disabled {
      background: #b6a500;
      cursor: initial;
      color: #000000 !important;

      &:hover {
        transform: initial;
      }
    }
  }

  .content {
    display: flex;
    justify-content: center;
    align-self: center;
    flex-direction: column;
    align-items: center;
    height: 100%;

    .wof-container--body {
      @media screen and (max-width: 800px) {
        width: auto;
        padding: 0;
        margin: 0;
      }
    }

    .roulette-logo {
      height: 15vh;
      width: auto;
      margin-top: -5vh;
    }

    .megapuesta-logo {
      height: 5vh;
      margin-top: 2vh;
    }

    .logo {
      width: fit-content;
      display: flex;
    }

    .subtitle {
      margin: 0;
      max-width: 32vw;
      font-weight: bold;
      font-size: 32px;
      word-break: break-word;
      text-align: center;

      .blue {
        color: #047968;
      }
    }
  }
}

.modal {
  display: flex;
  width: 100vw;
  height: 100vh;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .opaque {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.5);
  }

  .modal-wrapper {
    display: flex;
    position: relative;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    max-width: calc(100vw / 2);
    min-width: 600px;
    background: #1e1e26 0% 0% no-repeat padding-box;
    border-radius: 24px;

    .background {
      width: 100%;
      height: fit-content;
    }

    .content {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      text-align: center;
      height: 100%;
      box-sizing: border-box;
      padding: 20% 15%;

      .title {
        color: #f1cf00;
        margin: 28px 0 0 0;
        font-size: 32px;
        font-weight: normal;
      }

      .separator {
        border: 1px solid #ffffff1a;
        width: 100%;
        margin: 8px 0;
      }

      .body {
        margin: 0;
        color: #ffffff;
        font-size: 24px;
        font-weight: bold;
        line-height: 32px;

        .yellow {
          font-size: 32px;
          color: #f1cf00;
          line-height: 40px;
        }
      }

      .button {
        width: 100%;
        margin-top: 20px;
      }
    }
  }
}

.error-modal {
  background: #12121a;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  position: fixed;
  left: 0;
  top: 0;

  .error-body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;

    .title {
      margin: 32px 0 24px 0;
      font-size: 32px;
      color: #f1cf00;
    }

    .description {
      color: #ffffffde;
      font-size: 16px;
      margin: 0 0 48px 0;
    }

    .button {
      margin: 0;
    }
  }
}

@media (max-width: 800px) {
  .wrapper .button {
    padding: 10px 22px;
    font-size: 13px;
  }
  .wrapper .content .subtitle {
    font-size: 24px;
    max-width: 100vw;
  }

  .wrapper .wheel {
    width: 80vw;
  }

  .wrapper {
    background-image: url("images/main_bg_mobile.jpg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  }

  .modal .modal-wrapper {
    min-width: initial;
    max-width: initial;
    width: 90%;

    .background {
      height: auto;
    }

    .content {
      .title {
        font-size: 17px;
        font-weight: bold;
      }

      .body {
        font-size: 22px;
        font-weight: normal;

        .yellow {
          font-weight: bold;
        }
      }
    }
  }
  .error-modal .error-body .title {
    font-size: 24px;
  }
  .rootWheel {
    margin: 5% 0;
  }
}

@media (max-height: 425px) {
  .wrapper {
    @media (orientation: landscape) {
      // height: fit-content;
      min-height: 100vh;
    }
  }

  .wrapper .content {
    @media (orientation: landscape) {
      background-size: cover;
      height: fit-content;
    }
  }

  .wof-container--body {
    @media (orientation: landscape) {
      padding: 0;
    }
  }

  .wrapper .wheel {
    @media (orientation: landscape) {
      width: 80vh;
    }
  }

  .roulette-logo {
    @media (orientation: landscape) {
      display: none;
    }
  }

  .megapuesta-logo {
    @media (orientation: landscape) {
      display: none;
    }
  }

  .wrapper .button {
    @media (orientation: landscape) {
      padding: 10px 22px;
      margin-bottom: 10px;
    }
  }
}
