.no-data {
  &-loading {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    margin: auto;
    background: linear-gradient(180deg, #000000 0%, rgba(54, 44, 80, 0.33) 100%);
  }

  &-placeholder {
    flex-direction: column;
    align-self: center;
    margin-top: auto;

    & span {
      font-size: em(16px);
      color: #B8B1DD;
    }
  }

  &-svg {
    width: 100px;
    height: 100px;
    margin: auto;
  }
}
