@import "variables";

.wrapper {
  font-family: "Open Sans", sans-serif;
  display: flex;
  flex-direction: column;
  background-image: url("images/image_backgroundDesktop.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  height: 100vh;
  width: 100vw;
  justify-content: center;
  align-items: center;
  user-drag: none;
  -webkit-user-drag: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;

  @media screen and (max-width: 800px) {
    background-image: none !important;
    background-color: $black-2;
  }


  .rootWheel {
    position: relative;
  }

  .spin-button {
    position: absolute;
    outline: none;
    border: none;
    cursor: pointer;
    background: transparent;

    &:disabled {
      cursor: initial;
      filter: contrast(0.8);
    }
  }

  .wheel {
    width: calc(40vh + 20px);
    z-index: 100;
  }

  .absolute {
    position: absolute;
    bottom: 0;
  }

  .button {
    text-decoration: none;
    outline: none;
    border: none;
    z-index: 100;
    color: $white;
    background: #a48333;
    align-self: center;
    font-weight: bold;
    font-size: 16px;
    border-radius: 38px;
    max-width: 500px;
    width: 90%;
    margin-top: 8vh;
    padding: 20px 56px;
    transition: 0.5s ease all;
    cursor: pointer;

    @media screen and (max-width: 800px) {
      margin-top: 1vh;
    }

    &:hover {
      transform: scale(1.05);
    }

    &:disabled {
      background: rgba(171, 130, 27, 0.6);
      cursor: initial;
      color: $white !important;

      &:hover {
        transform: initial;
      }
    }
  }

  .overlay {
    background-color: rgba(0, 0, 0, 0.85);
    width: 100%;
    height: 100%;
    position: fixed;
    left: 0;
    bottom: 0;
    right: 0;
    top: 0;
  }

  .content {
    display: flex;
    justify-content: center;
    align-self: center;
    flex-direction: column;
    align-items: center;
    height: 100%;
    position: relative;

    @media screen and (max-width: 800px) {
      justify-content: start !important;
      padding-top: 30px;
      width: 100%;
    }


    .imgLogoContainer {
      display: flex;
      justify-content: center;
      align-self: center;
      flex-direction: column;
      align-items: center;
      @media screen and (max-width: 800px) {
        width: 100%;
      }
    }

    .wof-container--body {
      @media screen and (max-width: 800px) {
        width: auto;
        padding: 0;
        margin: 0;
      }
    }

    .wof-container--body-button {
      margin-top: 20px;
    }

    .spin247-logo {
      height: 5vh;
      margin: 0 auto;
      margin-bottom: 15px;
    }

    .roulette-logo {
      height: 15vh;
      width: auto;
      margin: 0 auto;
    }

    .logo {
      width: fit-content;
      display: flex;
    }

    .subtitle {
      margin: 0;
      max-width: 32vw;
      font-weight: bold;
      font-size: 32px;
      word-break: break-word;
      text-align: center;

      .blue {
        color: #047968;
      }
    }
  }
}

.modal {
  display: flex;
  width: 100vw;
  height: 100vh;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .opaque {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    //background-color: rgba(0, 0, 0, 0.5);
    background-color: rgba(0, 0, 0, 0);
  }

  .modal-wrapper {
    background: url("images/bg_popup_desktop.png") no-repeat center;
    position: relative;
    min-width: 1312px;
    margin: 0 auto;
    border-radius: 20px;

    @media screen and (max-width: 800px) {
      width: 94vw !important;
      height: 68vh;
      background: url("images/bg_popup_mobile.png") no-repeat center;
      background-size: 100% auto !important;
    }


    .content {
      display: flex;
      flex-direction: column;
      text-align: center;
      height: 100%;
      box-sizing: border-box;
      padding: 30px 0 0;

      .title {
        color: $white !important;
        padding: 10px 20px;
        margin-bottom: 20px;

        h2 {
          margin: 0;
          background-color: #a48333;
          display: inline;
          padding: 0 15px;
          font-weight: bold;
          font-size: 30px;
        }

        @media screen and (max-width: 800px) {
          padding: 0 20px 10px 20px;
          h2{
            font-size: 16px;
            padding: 5px 15px;
          }
        }
      }

      .body {
        font-size: 50px;
        margin-bottom: 20px;

        .first {
          font-size: 48px;
          line-height: 50px;
        }

        .second {
          font-size: 88px;
          line-height: 90px;
          color: $critical;
        }

        .third {
          font-size: 100px;
          line-height: 102px;

          span {
            border-bottom: 2px solid $critical;
          }
        }

        p {
          padding: 10px 0;
          margin: 0;
        }

        @media screen and (max-width: 800px) {
          margin-bottom: 10px;

          .first {
            font-size: 28px;
            line-height: 30px;
          }

          .second {
            font-size: 40px;
            line-height: 42px;
          }

          .third {
            font-size: 58px;
            line-height: 60px;
          }
        }
      }

      .gift {
        font-size: 18px;
        font-weight: bold;
        @media screen and (max-width: 800px) {
          font-size: 10px;
        }
      }

      .button-wrapper {
        margin: 20px auto;
        display: flex;
        justify-content: center;
        @media screen and (max-width: 800px) {
          max-width: 60vw;
        }
      }


    }

    .bottom-container {
      background-color: $gray-1;
      width: 100%;
      border-bottom-left-radius: 20px;
      border-bottom-right-radius: 20px;

      p {
        margin: 0;
        padding: 20px 0;
        font-size: 14px;
        text-align: center;
        @media (max-width: 800px) {
          padding: 20px 10px;
        }
      }
    }
  }
}

.error-modal {
  background: #12121a;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  position: fixed;
  left: 0;
  top: 0;

  .error-body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;

    .title {
      margin: 32px 0 24px 0;
      font-size: 32px;
      color: #a48333;
    }

    .description {
      color: $white-2;
      font-size: 16px;
      margin: 0 0 48px 0;
    }

    .button {
      margin: 0;
    }
  }
}

@media (max-width: 800px) {
  .wrapper .button {
    padding: 10px 22px;
    font-size: 13px;
  }
  .wrapper .content .subtitle {
    font-size: 24px;
    max-width: 100vw;
  }

  .wrapper .wheel {
    width: 80vw;
  }

  .wrapper {
    background-image: url("images/main_bg_mobile.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  }

  .modal .modal-wrapper {
    min-width: initial;
    max-width: initial;
    width: 90%;

    .background {
      height: auto;
    }

    .content {
      .title {
        font-size: 17px;
        font-weight: bold;
      }

      .body {
        font-size: 22px;
        font-weight: normal;

        .yellow {
          font-weight: bold;
        }
      }
    }
  }
  .error-modal .error-body .title {
    font-size: 24px;
  }
  .rootWheel {
    margin: 5% 0;
  }
}

@media (max-height: 425px) {
  .wrapper {
    @media (orientation: landscape) {
      // height: fit-content;
      min-height: 100vh;
    }
  }

  .wrapper .content {
    @media (orientation: landscape) {
      background-size: cover;
      height: fit-content;
    }
  }

  .wof-container--body {
    @media (orientation: landscape) {
      padding: 0;
    }
  }

  .wrapper .wheel {
    @media (orientation: landscape) {
      width: 80vh;
    }
  }

  .roulette-logo {
    @media (orientation: landscape) {
      display: none;
    }
  }

  .spin247-logo {
    @media (orientation: landscape) {
      display: none;
    }
  }

  .wrapper .button {
    @media (orientation: landscape) {
      padding: 10px 22px;
      margin-bottom: 10px;
    }
  }
}

.wof-container--body-ctas button:first-of-type {
  background: rgba(0, 0, 0, 0);
  color: #a48333;
  margin-right: 0.625em;
}

.wof-container--body {
  .modal-container {
    background-color: rgba(0, 0, 0, 0.85);
  }
}

.overflow-hidden {
  .overlay {
    display: none;
  }

  .imgLogoContainer {
    position: absolute;
    top: 2vh;
    z-index: 99;
  }

  .imgLogoContainer + .error-modal {
    img {
      display: none;
    }
  }
}

.errorButton {
  background-color: $critical;
  color: $white;
}

.errorTitle {
  color: $critical;
}

@media screen and (max-width: 800px) {
  .wof-container--body {
    padding: 20px 0 !important;
    margin-top: 30px !important;

    .wof-container--body-wrapper {
      margin: 20px 8px;
      display: flex;
      justify-content: space-between;
      gap: 7px;

      .wof-container--body-spot {
        width: 72px;
        height: 72px;
        border-radius: 4px;
        font-size: 14px;
        padding: 0;
      }
    }

    .wof-container--body-ctas {
      flex-direction: column;

      button:first-child {
        margin-bottom: 20px;
      }
    }
  }

}

