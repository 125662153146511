.leaderboard {
  &-rules {
    margin-left: 10px;

    & button {
      background: transparent;
      border: 1px solid #a48333;
      border-radius: 50%;
      width: 1.5em;
      height: 1.5em;
      color: white;
      font-size: .7em;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }
  }

  &-container {
    background-color: #1f1f1f;
    display: flex;
    flex-direction: column;
    min-width: 30%;
    border-radius: 8px;
    box-shadow: 0 5px 20px rgb(0 0 0 / 60%);
    padding: 0.5rem 0;
    font-family: "Open Sans", sans-serif;
    font-size: 16px;

    &--active {
      .leaderboard-header {

        &--info {
          background: transparent linear-gradient(270deg, #edd185, #ffb12b) 0 0 no-repeat padding-box;

          &-item {
            &:not(:last-child) {
              border-right: 1px solid #eab249;
            }

            & > span {
              color: #fff;
            }

            &--sub {
              & span {
                color: #101010;
              }
            }
          }
        }
      }

      .leaderboard-body {
        min-height: 10rem;
        max-height: 20rem;
      }
    }
  }


  &-subheader {
    padding: 1rem 0;
    background: #1a1a1a;
    color: white;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &--position {
      padding: 0 1rem;
      font-size: 0.9rem;
    }

    &--button {
      background-color: #a48333;
      cursor: pointer;
      color: white;
      padding: 0.5rem 1.5rem;
      border-radius: 2rem;
      border: 1px solid #a48333;
      font-size: .8rem;
      margin-right: 1rem;

      &:disabled {
        opacity: .6;
        cursor: not-allowed;
      }
    }
  }

  &-header {
    padding: 0 1rem;

    &--logo {
      max-width: 100%;
      height: auto;
      cursor: pointer;
    }

    &--wrapper {
      display: flex;
    }

    &--info {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-evenly;
      background: transparent linear-gradient(286deg, #454545, #101010) 0 0 no-repeat;
      box-shadow: 13px 16px 26px rgb(0 0 0 / 5%);
      border-radius: 8px;
      padding: 0.4rem 1rem;
      margin-top: .7em;

      &-item {
        &:not(:last-child) {
          margin-right: 1em;
          padding-right: 1em;
          border-right: 1px solid hsla(0, 0%, 100%, .1);
        }

        & > span {
          font-weight: 600;
          color: #707070;
          font-size: .7rem;
        }

        &--sub {
          & span {
            color: white;
            font-size: .8rem;
          }

          & span:first-child {
            font-weight: 800;
            margin-right: .3rem;
          }

          & span:last-child {
            font-weight: 400;
          }
        }
      }
    }

    &--title {
      font-size: 1.25rem;
      color: #979797;
      letter-spacing: 0.2rem;
    }
  }

  &-body {
    overflow: hidden;
    max-height: 10rem;
    position: relative;
    transition: max-height 250ms ease-in-out;

    &--modal {
      background-color: #2b2b2b;
      min-width: 75%;
      border-radius: 8px;
      overflow: hidden;
      z-index: 100;

      &-header {
        color: white;
        text-align: center;
        padding: 1em 2.5em;
        border-bottom: 1px solid white;
      }

      &-body {
        display: inline-flex;
        padding: 1em;
        gap: 1em;
        color: white;

        &--game {
          width: 100%;
          height: auto;
          overflow: hidden;
          cursor: pointer;

          & img {
            width: inherit;
            height: auto;
          }
        }
      }
    }

    &--expanded {
      max-height: 20rem;
      overflow: auto;
    }

    &--header {
      background-color: #2b2b2b;
      color: #a48333;
      padding: .6rem .2rem;
      font-size: .8rem;
      font-weight: 500;
      position: sticky;
      top: 0;
    }

    &--content {
      display: flex;
      flex-direction: column;
      color: white;
      font-size: .8rem;

      &-evolution {
        display: flex;
        align-items: center;
        justify-content: center;

        &--neutral {
          width: 1.3rem;
          border-radius: 2pt;
          height: 4px;
          background-color: #a48333;
          margin-right: .7rem;
        }

        &--positive {
          color: #098d4b;
        }

        &--negative {
          color: #ED3030;
        }
      }


      & .leaderboard-body--td {
        padding: .6rem .2rem;
      }

      & .leaderboard-body--tr {
        &:nth-child(odd) {
          background-color: #1a1a1a;
        }
      }
    }

    &--footer {
      position: sticky;
      bottom: 0;
      color: white;
      font-size: .8rem;
      background: #1f1f1f;
      display: flex;
      align-items: center;
      justify-content: center;

      &--inverted-image {
        transform: rotate(180deg);
      }

      & img {
        width: 1.2em;
        height: 1.2em;
        margin-left: .7em;
        border: 1px solid #a48333;
        border-radius: 50%;
        padding: .2em;
        vertical-align: middle;
        transition: transform 500ms ease-in-out;
      }

      & span,
      & img {
        cursor: pointer;
      }
    }

    &--tr {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 0 1rem;
      transition: background-color 500ms ease-in-out;

      &-active {
        background-color: #a48333;
      }
    }

    &--td {
      flex: 1;
      text-align: center;
    }
  }

}
