@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,400;0,500;0,600;0,700;0,800;1,400;1,500;1,600;1,700;1,800&display=swap');
@import "functions";
@import "variables";
@import "modal";

html, body {
  width: 100%;
  height: 100%;
}

body {
  margin: 0;
  padding: 0;
}

#root {
  width: 100%;
  height: 100%;
}

.wof-container {
  background-image: url(../assets/wof/background.png);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
  font-size: 16px;
  flex-direction: column;
  justify-content: center;
  font-family: Montserrat, sans-serif;
  overflow: hidden;
  display: none;

  &--show {
    display: flex;
  }

  &--wheel {
    position: relative;

    &-skin {
      position: absolute;
      left: 50%;
      top: 49.7%;
      transform: translate(-50%, -50%);
      width: 100%;
    }
  }

  &--logo {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100%;
    padding-top: 1em;
    align-self: flex-start;

    & img:first-child {
      margin-bottom: 1em;
      width: 3.5em;
    }

    & img {
      width: em(240);
      cursor: pointer;
    }
  }

  &--body {
    background-color: #141414;
    font-family: Montserrat, sans-serif;
    width: calculateWofWidth($maximumSpots, $spotWidth, $spotGap);
    margin: em(32) auto 0 auto;
    padding: em(15) em(10);
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: em(10);
    box-shadow: 0 em(10px) em(40px) black;

    & button {
      display: flex;
      align-items: center;
      justify-content: space-evenly;

      &:disabled {
        color: rgba(255, 255, 255, .2) !important;
        cursor: not-allowed !important;
      }
    }

    .loader,
    .loader:after {
      border-radius: 50%;
      width: 10em;
      height: 10em;
    }

    .loader {
      font-size: 4px;
      position: relative;
      text-indent: -9999em;
      border-top: 1.1em solid rgba(255, 255, 255, 0.2);
      border-right: 1.1em solid rgba(255, 255, 255, 0.2);
      border-bottom: 1.1em solid rgba(255, 255, 255, 0.2);
      border-left: 1.1em solid #ffffff;
      transform: translateZ(0);
      animation: load8 1.1s infinite linear;
    }

    @-webkit-keyframes load8 {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }

    @keyframes load8 {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }

    &--noBg {
      background-color: transparent;
      box-shadow: none;
    }

    &-win {
      width: 90%;
      height: 80%;
      background-image: url(../assets/wof/modal_bg.png);
      background-repeat: no-repeat;
      background-position: center;
      background-size: 100% 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      text-transform: uppercase;
      text-align: center;
      font-weight: bold;
      position: relative;
      border-radius: em(20);
      overflow: hidden;

      &--footer {
        background-color: rgba(32, 32, 32, .85);
        color: white;
        width: 100%;
        position: absolute;
        left: 40%;
        bottom: 0;
        transform: translate(-40%, 0);
        padding: em(10) em(30);
        font-size: em(12);
        text-transform: none;
      }

      &--wrapper {
        display: inline-block;
        min-width: em(50);
        max-width: 65%;
      }


      &--title {
        background-color: #A48333;
        font-size: em(29);
        letter-spacing: em(8);
        padding: em(2) em(5);
      }

      &--prize {
        color: #A48333;
        font-size: em(70);
        letter-spacing: em(2);
        line-height: em(20);
        word-break: break-word;
      }

      &--prizeType {
        font-size: em(32.5);
        line-height: em(15);
      }

      &--subtitle {
        letter-spacing: em(5);
        font-size: em(30);
        line-height: em(20);
      }

      &--extra {
        border-top: 2px solid #A48333;
        margin-top: em(10);
        padding-top: em(10);
        font-size: em(18);
      }
    }

    &-button {
      background: #A48333;
      border: 1px solid #A48333;
      padding: em(14);
      min-width: em(300);
      font-size: em(14);
      border-radius: em(36);
      color: white;
      text-transform: uppercase;
      font-weight: 800;
      font-family: "Montserrat", sans-serif;
      cursor: pointer;
      margin: em(5) auto 0 auto;
    }

    &-popup {
      position: absolute;
      left: 50%;
      bottom: -50%;
      transform: translate(-50%, 50%);
      border: 1px solid;
      background-color: white;
      display: flex;
      align-items: center;
      border-radius: em(2px);
      overflow: hidden;
      width: calculateWofWidth($maximumSpots, $spotWidth, $spotGap);
      transition: all .5s ease-in-out;

      &--show {
        bottom: 2%;
        transform: translate(-50%, -2%);
      }

      &--error {
        border-color: #C74244;
        color: #C74244;

        & .wof-container--body-popup--mark {
          background: #C74244;
        }
      }

      &--success {
        border-color: #4AA255;
        color: #4AA255;

        & .wof-container--body-popup--mark {
          background: #4AA255;
        }
      }

      &--close {
        color: #292929;
        cursor: pointer;
        font-size: em(25);
        align-self: flex-start;
        margin-top: -#{em(3)};
        margin-left: auto;
        margin-right: em(3);
      }

      &--message {
        font-size: em(14);
        margin-left: em(15);
      }

      &--mark {
        background: #C74244;
        display: flex;
        align-items: center;
        justify-content: center;

        & img {
          padding: em(12) em(10);
        }
      }
    }

    &-wrapper {
      display: inline-flex;
      flex-direction: row;
      flex-wrap: wrap;
      gap: em(10);
      justify-content: center;
    }

    &-ctas {
      font-size: 16px;
      margin-top: em(10);
      display: flex;

      & button {
        border: 1px solid #A48333;
        font-family: Montserrat, sans-serif;
        border-radius: em(36);
        font-size: 1em;
        padding: em(10) em(20);
        min-width: em(160);
        cursor: pointer;

        &:first-child {
          background: transparent;
          color: #A48333;
          margin-right: em(10);
        }

        &:last-child {
          background: #A48333;
          color: white;
        }
      }
    }

    &-spot {
      border: 1px solid #A48333;
      width: em(100);
      height: em(100);
      border-radius: em(4);
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      white-space: pre-line;
      word-break: break-word;
      opacity: 25%;
      padding: em(5);
      transition: all 250ms ease-in-out;

      &--active {
        opacity: 1;
        background: #A48333;
      }
    }
  }
}
