.loading {
  display: flex;
  align-items: center;
  justify-content: center;
  // height: 100%;
  // width: 100%;
  background: transparent;

  .svg {
    width: 100px;
    height: 100px;
    margin: auto;
    fill: #5b5c5f;
  }
}
