.modal {
  &-container {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;
    background-color: rgba(0, 0, 0, .45);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    &--hidden {
      display: none;
    }
  }
}

.overflow-hidden {
  overflow: hidden;
}
